const constants = {
  // Keep this sync'd with EVERLANE::DEFAULT_DESCRIPTION
  DEFAULT_DESCRIPTION:
    'Shop Everlane now for modern essentials. We make the most beautiful essentials, at the best factories, without traditional markups. Free shipping on 2+ items.',
  URL_BASE: process.env.NEXT_PUBLIC_WWW_ROOT || 'https://www.everlane.com',
  DEFAULT_IMAGE:
    'https://res.cloudinary.com/everlane/image/upload/c_scale/c_fill,dpr_auto,f_auto,q_auto,w_1200/v1/i/a17a572e_faf1.jpg',
  DESKTOP_PRODUCT_PAGE_GALLERY: 'desktop-pdp-gallery',
  ALGOLIA_INDEX_NAME:
    process.env.NODE_ENV === 'production' ? 'Product_production' : 'Product_development',
}

export const ERROR_MESSAGES = {
  OUT_OF_STOCK_ERROR: `We're sorry, the item is no longer available.`,
  GENERIC_ERROR: `We're sorry, something went wrong. Please try again.`,
}

export default constants
