/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import { useRef, useEffect, DependencyList } from 'react'

const isBrowser = typeof window !== `undefined`

function getScrollPosition({ element, useWindow }: { element?: any; useWindow?: boolean }) {
  if (!isBrowser) return { x: 0, y: 0 }

  const target = element ? element.current : document.body
  const position = target.getBoundingClientRect()

  return useWindow
    ? { x: window.scrollX, y: window.scrollY }
    : { x: position.left, y: position.top }
}

function useScrollPosition(
  effect: ({ prevPos, currPos }: { prevPos: any; currPos: any }) => any,
  deps?: DependencyList,
  element?: any,
  useWindow?: boolean,
  wait?: number,
) {
  const position = useRef(getScrollPosition({ useWindow }))

  let throttleTimeout = null

  const callback = () => {
    const currPos = getScrollPosition({ element, useWindow })
    effect({ prevPos: position.current, currPos })
    position.current = currPos
    throttleTimeout = null
  }

  useEffect(() => {
    const handleScroll = () => {
      if (wait && throttleTimeout === null) {
        throttleTimeout = setTimeout(callback, wait)
      } else if (!wait) {
        callback()
      }
    }

    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
      clearTimeout(throttleTimeout)
    }
  }, deps)
}

export default useScrollPosition
