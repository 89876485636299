import React, { useState, useEffect } from 'react'
import Head from 'next/head'
import dynamic from 'next/dynamic'

import { publishEvent } from 'lib/events/tracking'
import constants from 'lib/constants'

import useGeolocation from 'lib/network/hooks/useGeolocation'
import useScrollPosition from 'lib/hooks/useScrollPosition'

import Layout from 'components/Layout'

import Event from 'types/Event'

import styles from './styles.module.scss'

const BuilderComponent = dynamic(() => import('components/core/BuilderComponent'))

const Homepage = () => {
  const { data: geolocation } = useGeolocation()

  const [triggerAuthModal, setTriggerAuthModal] = useState(false)

  useEffect(() => {
    publishEvent(Event.Home.PAGE_VIEW)
  }, [])

  // Setup an event listener for builder homepage login prompt
  useEffect(() => {
    function openAuthModal(event) {
      if (event.target.matches('.login-prompt')) {
        event.preventDefault()
        setTriggerAuthModal(true)
        setTimeout(() => {
          setTriggerAuthModal(false)
        }, 420)
      }
    }

    global.document.addEventListener('click', openAuthModal)

    return () => {
      global.document.removeEventListener('click', openAuthModal)
    }
  }, [])

  useScrollPosition(
    ({ currPos }) => {
      const clientScrollY = currPos.y * -1
      publishEvent(Event.Feed.SCROLL, {
        scroll_y: clientScrollY,
      })
    }, // effect
    [], // deps
    null, // element
    null, // useWindow
    2000, // wait 2000ms
  )

  return (
    <Layout showFooter triggerAuthModal={triggerAuthModal}>
      <Head>
        <title>Everlane</title>
        <meta property="description" name="description" content={constants.DEFAULT_DESCRIPTION} />
        <meta property="og:title" content="Everlane" />
        <meta property="og:description" content={constants.DEFAULT_DESCRIPTION} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={constants.URL_BASE} />
        <meta property="og:site_name" content="Everlane" />
        <meta property="og:image" name="og:image" content={constants.DEFAULT_IMAGE} />
        <link rel="canonical" href={constants.URL_BASE} />
      </Head>
      <div className={styles['builder-homepage__wrapper']}>
        <BuilderComponent
          model="homepage"
          data={{
            shippingPolicy: geolocation?.shippingPolicy,
          }}
        />
      </div>
    </Layout>
  )
}

export default Homepage
